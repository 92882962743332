import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { productsActions, commonActions, utilsActions } from "services/actions";

export const useActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      ...productsActions,
      ...commonActions,
      ...utilsActions,
    },
    dispatch
  );
};
