import React from "react";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import { Link } from "react-router-dom";

const GoBack = () => {
  return (
    <div className="pl-3 pt-12 sm:hidden">
      <Link to="/" className="flex items-center space-x-2">
        <HiOutlineArrowLongLeft size={25} style={{ marginTop: "0.25rem" }} />
        <p className="text-sm">Go back</p>
      </Link>
    </div>
  );
};

export default GoBack;
