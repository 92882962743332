import _ from "lodash";

export const getProductSlug = (product) => {
  if (product) {
    const { productName, itemCode } = product;
    const slug = `${productName
      .split(" ")
      .join("-")
      .split("/")
      .join("-")}-${itemCode}`;
    return slug.toLowerCase();
  }
};

export const capitalizeFirstLetters = (sentence) => {
  if (sentence) {
    const lowerCaseSentence = sentence?.toLowerCase();
    let splitSentence = lowerCaseSentence?.split(" ");

    for (let i = 0; i < splitSentence.length; i++) {
      splitSentence[i] =
        splitSentence[i].charAt(0).toUpperCase() + splitSentence[i].slice(1);
    }

    return splitSentence.join(" ");
  } else {
    return null;
  }
};

export const paginate = (items, pageNumber, pageSize) => {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
};
