import React from "react";
import Grid from "@mui/material/Grid";

const Spinner = ({ loaderHeight }) => {
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: loaderHeight,
        background: "#fff",
      }}
    >
      <img
        src="/loading-screen.gif"
        alt="loading spinner"
        style={{ width: 150 }}
      />
    </Grid>
  );
};

export default Spinner;
