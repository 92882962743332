import { Link } from "react-router-dom";
import { IoChevronForwardOutline } from "react-icons/io5";
import { capitalizeFirstLetters } from "libs/helpers";

const ProductDetailsHeading = ({ product }) => {
  return (
    <ul className="bg-white py-4 px-2 mb-4 hidden sm:flex sm:items-center">
      <li className="flex items-center text-sm">
        <Link to="/">Home</Link>
        <span className="mt-0.5">
          <IoChevronForwardOutline size={15} color="rgba(107, 114, 128, 0.9)" />
        </span>
      </li>
      <li className="flex items-center text-sm">
        <Link to={`/products/categories/${product?.category?.toLowerCase()}`}>
          {capitalizeFirstLetters(product?.category)}
        </Link>
        <span className="mt-0.5">
          <IoChevronForwardOutline size={15} color="rgba(107, 114, 128, 0.9)" />
        </span>
      </li>
      <li className="flex items-center text-sm">
        <Link
          to={`/products/categories/${encodeURIComponent(
            product?.category?.toLowerCase()
          )}/${encodeURIComponent(product?.subCategory?.toLowerCase())}`}
        >
          {capitalizeFirstLetters(product?.subCategory)}{" "}
        </Link>
        <span className="mt-0.5">
          <IoChevronForwardOutline size={15} color="rgba(107, 114, 128, 0.9)" />
        </span>
      </li>
      <li className="flex items-center text-sm text-gray-500">
        {capitalizeFirstLetters(product?.productName)}
      </li>
    </ul>
  );
};

export default ProductDetailsHeading;
