import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useActions } from "hooks/useActions";
import ProductsList from "components/product/ProductsList";
import { useSelector } from "react-redux";
import { productsSelector } from "services/products";
import useTitle from "hooks/useTitle";

const CategoryName = ({ search, isOnline, setOpenMobileNavigation }) => {
  const { category } = useParams();
  useTitle(`Products : ${decodeURIComponent(category)}`);
  const { products, productsByCategory } = useSelector(productsSelector);

  const { getProductsByCategory, setCurrentProductsList } = useActions();

  useEffect(() => {
    if (products?.length > 0) {
      getProductsByCategory(category);
    }

    setOpenMobileNavigation(false);

    // eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    if (productsByCategory?.length) {
      setCurrentProductsList(productsByCategory);
    }

    // eslint-disable-next-line
  }, [productsByCategory]);

  return (
    <div>
      <p className="font-md font-semibold text-black mb-3">{category}</p>
      <section>
        <ProductsList
          isOnline={isOnline}
          search={search}
          productData={productsByCategory}
        />
      </section>
    </div>
  );
};

CategoryName.propTypes = {
  search: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
};

export default CategoryName;
