import React, { useState, Fragment } from "react";
import ProductItem from "./ProductItem";
import { productsSelector } from "services/products";
import { useSelector } from "react-redux";
import Pagination from "utils/Pagination";
import { paginate } from "libs/helpers";
import PropTypes from "prop-types";
import Spinner from "utils/Spinner";
import ProductNotFound from "utils/NotFound";

const ProductsList = ({ search, isOnline, productData }) => {
  const { loading, filteredProducts } = useSelector(productsSelector);
  const pageSize = 96;
  const [page, setPage] = useState(1);

  const paginatedProducts = paginate(
    search ? filteredProducts : productData,
    page,
    pageSize
  );

  const handleChange = (event, value) => {
    window.scrollTo(0, 0);
    setPage(value);
  };

  return (
    <Fragment>
      {loading && productData?.length === 0 ? (
        <Spinner loaderHeight="100vh" />
      ) : (
        <Fragment>
          <div className="grid gap-4 xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {paginatedProducts?.map((product) => (
              <ProductItem
                key={product._id}
                product={product}
                isOnline={isOnline}
              />
            ))}
          </div>
          {!search && filteredProducts.length === 0 && (
            <div className="my-8 flex justify-center">
              <Pagination
                items={search ? filteredProducts?.length : productData?.length}
                pageSize={pageSize}
                page={page}
                onChange={handleChange}
                setPage={setPage}
              />
            </div>
          )}
          {search.length > 3 && filteredProducts.length === 0 && (
            <ProductNotFound
              loading={loading}
              items={search ? filteredProducts : productData}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

ProductsList.propTypes = {
  search: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
};

export default ProductsList;
