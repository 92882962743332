import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useActions } from "hooks/useActions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { categoriesSelector } from "services/common";

const AsideMenuItem = ({ category }) => {
  const { category: categoryName } = useParams();
  const { currentCategory } = useSelector(categoriesSelector);
  const { setCurrentCategory, handleFilterNavSubCategories } = useActions();

  useEffect(() => {
    if (categoryName) {
      setCurrentCategory(decodeURIComponent(categoryName));
    }
    // eslint-disable-next-line
  }, [categoryName]);

  useEffect(() => {
    if (currentCategory) {
      handleFilterNavSubCategories(currentCategory);
    }
    // eslint-disable-next-line
  }, [currentCategory]);

  const handleSetCurrentCategory = () => {
    setCurrentCategory(category.categoryName);
  };

  const isMatch =
    categoryName?.toLowerCase() === category?.categoryName?.toLowerCase() ||
    (!categoryName &&
      currentCategory?.toLowerCase() === category?.categoryName?.toLowerCase());

  return (
    <li
      className={`font-normal text-md ${
        isMatch ? "bg-gray-800/50 text-white" : "bg-transparent text-black"
      } hover:bg-white  hover:text-secondary hover:font-semibold`}
      onMouseOver={handleSetCurrentCategory}
    >
      <Link
        to={`/products/categories/${encodeURIComponent(
          `${category?.categoryName}`
        )}`}
        className="px-6 py-3 block"
      >
        {category?.categoryName}
      </Link>
    </li>
  );
};

export default AsideMenuItem;
