import { configureStore, combineReducers } from "@reduxjs/toolkit";
import productsReducer from "./products";
import commonReducer from "./common";
import utilsReducer from "./utils";
import { persistStore, persistReducer } from "reduxjs-toolkit-persist";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "reduxjs-toolkit-persist/lib/storage"; // defaults to localStorage for web
import { createFilter } from "redux-persist-transform-filter";

const productFilter = createFilter("product", [
  "productsByCategory",
  "productsBySubCategory",
  "filteredProducts",
  "productDataset",
  "loading",
  "loadingSingleProducts",
  "singleProduct",
  "error",
]);

const commonFilter = createFilter("coomon", [
  "filteredSubCategories",
  "navSubCategories",
]);

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["utils"],
  transforms: [productFilter, commonFilter],
};

const rootReducer = combineReducers({
  products: productsReducer,
  common: commonReducer,
  utils: utilsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
