import { useEffect } from "react";
import { Pagination as MuiPagination } from "@mui/material";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const StyledPagination = styled(MuiPagination)({
  "& .MuiPaginationItem-root.Mui-selected": {
    background: "#22358e",
  },
});

const Pagination = ({ items, pageSize, onChange, setPage, ...rest }) => {
  useEffect(() => {
    setPage(1);

    // eslint-disable-next-line
  }, []);

  const pagesCount = Math.ceil(items / pageSize);
  const pages = _.range(1, pagesCount + 1);

  return (
    <StyledPagination
      count={pages.length}
      color="secondary"
      boundaryCount={2}
      siblingCount={4}
      onChange={onChange}
      {...rest}
    />
  );
};

Pagination.propTypes = {
  items: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default Pagination;
