import React, { useCallback } from "react";
import { CiSearch } from "react-icons/ci";
import { useActions } from "hooks/useActions";
import PropTypes from "prop-types";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SearchInput = ({ search, setSearch }) => {
  const { itemCode } = useParams();
  const { pathname } = useLocation();
  const { handleFilteredProducts } = useActions();

  // eslint-disable-next-line
  const debounceFilteredProducts = useCallback(
    _.debounce(handleFilteredProducts, 500),
    []
  );

  const handleChange = (event) => {
    setSearch(event.target.value);
    debounceFilteredProducts(event.target.value);
  };

  if (pathname === `/products/${itemCode}`) return null;

  return (
    <div className="h-8 flex w-full sm:flex-1 sm:w-auto">
      <input
        className="outline-0 rounded-l-sm text-gray-700 px-2 h-full font-normal text-md flex-1"
        value={search}
        onChange={handleChange}
      />
      <button
        aria-label="search icon"
        className="px-4 bg-blue-600 rounded-r-md"
      >
        <CiSearch size={20} />
      </button>
    </div>
  );
};

SearchInput.propTypes = {
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};

export default SearchInput;
