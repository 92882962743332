import React from "react";
import { IoIosMenu } from "react-icons/io";
import SearchInput from "utils/SearchInput";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Header = ({
  search,
  setSearch,
  setOpenNavigation,
  setOpenMobileNavigation,
  children,
}) => {
  return (
    <header
      className={`bg-secondary w-full fixed z-20 px-4 text-white h-auto flex flex-col items-center gap-4 sm:flex-row sm:h-20`}
    >
      {/* Displays on LG screen whose min screen size is 1024px */}
      <div className="hidden z-20 relative lg:block">
        <IconButton onClick={() => setOpenNavigation((prev) => !prev)}>
          <IoIosMenu size={30} color="#fff" />
        </IconButton>
        {children}
      </div>
      {/* Only display on screen size is 640px and hidden on screen sizes whose size is 1024px */}
      <div className="z-20 relative hidden sm:block lg:hidden">
        <IconButton onClick={() => setOpenMobileNavigation((prev) => !prev)}>
          <IoIosMenu size={30} color="#fff" />
        </IconButton>
        {children}
      </div>
      <Link to="/">
        <img src="/brand-central-white.png" alt="Logo" style={{ width: 150 }} />
      </Link>
      <div className="flex items-center w-full sm:flex-1">
        {/* Hidden on screen sizes less than 640px */}
        <div className="self-center -ml-4 sm:hidden">
          <IconButton onClick={() => setOpenMobileNavigation((prev) => !prev)}>
            <IoIosMenu size={30} color="#fff" />
          </IconButton>
        </div>
        <SearchInput search={search} setSearch={setSearch} />
      </div>
      <div className="md:flex-1"></div>
    </header>
  );
};

Header.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  setOpenNavigation: PropTypes.func.isRequired,
  setOpenMobileNavigation: PropTypes.func.isRequired,
};

export default Header;
