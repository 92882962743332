import { useEffect, useState } from "react";

export const useConnectivity = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  function isReachable() {
    /**
     * Note: fetch() still "succeeds" for 404s on subdirectories,
     * which is ok when only testing for domain reachability.
     *
     * Example:
     *   https://google.com/noexist does not throw
     *   https://noexist.com/noexist does throw
     */
    return fetch("https://www.google.com", { method: "HEAD", mode: "no-cors" })
      .then(function (resp) {
        return resp?.ok || resp?.type === "opaque";
      })
      .catch(function (err) {
        console.warn("[conn test failure]:", err);
      });
  }

  useEffect(() => {
    function handleConnection() {
      if (navigator.onLine) {
        isReachable().then(function (online) {
          if (online) {
            // handle online status
            setIsOnline(true);
            console.log("online");
          } else {
            setIsOnline(false);
            console.log("no connectivity");
          }
        });
      } else {
        // handle offline statuss
        console.log("offline");
        setIsOnline(false);
      }
    }

    window.addEventListener("online", () => {
      console.log("Is Connected");
      handleConnection();
    });
    window.addEventListener("offline", () => {
      console.log("Is Offline");
      handleConnection();
    });
  }, []);

  return { isOnline };
};
