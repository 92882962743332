import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const Container = styled(Grid, {
  shouldForwardProp: (prop) => !["loading", "items"].includes(prop),
})(({ theme, loading, items }) => ({
  minHeight: "80vh",
  display: !loading && items.length === 0 ? "flex" : "none",

  [theme.breakpoints.down("sm")]: {
    minHeight: "70vh",
    padding: "0 2rem",
  },
}));

const ProductNotFound = ({ loading, items }) => {
  return (
    <Container
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      loading={loading}
      items={items}
    >
      <Grid item container justifyContent="center">
        <Grid item>
          <Grid item container direction="column" alignItems="center">
            <Grid item>
              <h2 className="text-3xl font-light uppercase leading-36 mb-2 text-gray-600 md:text-4xl lg:text-6xl">
                Sorry
              </h2>
            </Grid>
            <Grid item>
              <h3 className="text-2xl text-gray-600 tracking-wider font-light text-center md:text-5xl">
                We couldn&apos;t find that product.
              </h3>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

ProductNotFound.propTypes = {
  loading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
};

export default ProductNotFound;
