import { capitalizeFirstLetters } from "libs/helpers";
import React from "react";

const ProductInformation = ({ product, isOnline }) => {
  return (
    <main className="flex flex-col items-center gap-8 bg-white py-6 px-4 sm:flex-row ">
      {/* Image section */}
      <section className="w-64 sm:w-2/5">
        <img
          src={
            !product?.hasImage || !isOnline
              ? "/not-found-img.png"
              : product?.featuredImage
          }
          alt={product?.productName}
        />
      </section>
      <section className="w-full sm:w-3/5">
        <h2 className="text-xl font-semibold mb-3 text-secondary tracking-wide">
          {capitalizeFirstLetters(product?.productName)}
        </h2>
        <p className="text-sm mb-4">
          <span className="text-gray-400"> Product Code:</span>{" "}
          <span className="text-gray-800 text-md font-semibold tracking-wide">
            {product?.itemCode}
          </span>
        </p>
        <hr className="w-full mb-4" />
        <p className="mb-4 font-bold text-gray-800">
          <span className="font-normal text-gray-500">Price Code 1: </span>
          <span className="text-lg"> ${product?.priceCode1}</span>
        </p>
        <p className="mb-4 font-bold text-gray-800">
          <span className="font-normal text-gray-500">Price Code 2: </span>
          <span className="text-lg"> ${product?.priceCode2}</span>
        </p>
        <p className="mb-4 font-bold text-gray-800">
          <span className="font-normal text-gray-500">Price Code 3: </span>
          <span className="text-lg"> ${product?.priceCode3}</span>
        </p>
        <p className="mb-4 font-bold text-gray-800">
          <span className="font-normal text-gray-500">Price Code 4: </span>
          <span className="text-lg"> ${product?.priceCode4}</span>
        </p>
        <hr className="w-full mb-4" />
        <p className="mb-3 text-gray-800">
          <span className="text-gray-500">Brand: </span>
          <span className="font-semibold">{product?.brandName}</span>
        </p>
        <p className="mb-3 text-gray-800">
          <span className="text-gray-500">Product UPC: </span>
          <span className="font-semibold">{product?.productUPC}</span>
        </p>
        <p className="mb-3 text-gray-800">
          <span className="text-gray-500">Unit of Measurement: </span>
          <span className="font-semibold">{product?.units}</span>
        </p>
        <p className="mb-3 text-gray-800">
          <span className="text-gray-500">Category: </span>
          <span className="font-semibold">{product?.category}</span>
        </p>
        <p className="mb-3 text-gray-800">
          <span className="text-gray-500">Sub Category: </span>
          <span className="font-semibold">{product?.subCategory}</span>
        </p>
        <p className="mb-2 text-gray-800">
          <span className="text-gray-500">Product Weight: </span>
          <span className="font-semibold">
            {product?.shippingCategory
              ? capitalizeFirstLetters(product?.shippingCategory)
              : "Light"}
          </span>
        </p>
        <p className="mb-2 text-gray-800">
          <span className="text-gray-500">Stock Quantity: </span>
          <span className="font-semibold">{product?.productStock}</span>
        </p>
      </section>
    </main>
  );
};

export default ProductInformation;
