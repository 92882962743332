import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "services/axios";
import _ from "lodash";
// import { apiSlice } from "services/api/apiSlice";

const initialState = {
  loading: false,
  loadingSingleProduct: false,
  products: [],
  productDataset: [],
  productsByCategory: [],
  productsBySubCategory: [],
  productsBySubCategoryBrands: [],
  filteredProducts: [],
  singleProduct: null,
  error: null,
};

// const productsSlice = apiSlice.injectEndpoints({
//   endpoints: (builder) => ({
//     getProducts: builder.query({
//       query: (pageNumber) =>
//         `/api/products/paginated?page=${pageNumber}&limit=21`,
//       transformResponse: (responseData) => {
//         const response = responseData.data;

//         return { products: response.paginatedProducts, total: response.total };
//       },
//       validateStatus: (response, result) =>
//         response.status === 200 && !result.isError,
//       providesTags: ["Product"],
//     }),
//     getSingleProduct: builder.query({
//       query: (itemCode) => `/api/products/v1/${itemCode}`,
//       providesTags: ["Product"],
//     }),
//   }),
// });

export const fetchProducts = createAsyncThunk(
  "fetchAllProducts",
  async (thunkAPI) => {
    if (!navigator.onLine) return;
    try {
      const { data } = await axios.get("/api/products");
      return _.shuffle(
        data.data
          .filter((product) => product.productStatus)
          .sort((a, b) =>
            b.productStock <= 0 ? -1 : b.productStock > 0 ? 1 : 0
          )
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchSingleProduct = createAsyncThunk(
  "single-product",
  async (itemCode, thunkAPI) => {
    if (!navigator.onLine) return;
    try {
      const { data } = await axios.get(`/api/products/v1/${itemCode}`);

      return data.data.product;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setFilteredProducts: (state) => {
      state.filteredProducts = [];
    },
    setCurrentProduct: (state, action) => {
      state.singleProduct = action.payload;
    },
    setCurrentProductsList: (state, action) => {
      state.productDataset = action.payload;
    },
    handleFilteredProducts: (state, action) => {
      const keys = ["productName", "brandName", "itemCode", "productUPC"];
      if (action.payload) {
        const filterWords = action.payload.split(" ");
        state.filteredProducts = state.productDataset.filter((product) => {
          const allMatch = filterWords.every((word) => {
            return keys.some((key) => {
              if (product[key].toLowerCase().indexOf(word.toLowerCase()) > -1) {
                return true;
              } else {
                return false;
              }
            });
          });
          return allMatch;
        });
      } else {
        state.filteredProducts = [];
      }
    },
    getProductsByCategory: (state, action) => {
      const keys = ["category"];

      state.productsByCategory = state.products.filter((product) =>
        keys.every((key) =>
          product[key].toLowerCase().includes(action.payload.toLowerCase())
        )
      );
    },
    getProductsBySubCategory: (state, action) => {
      const keys = ["category", "subCategory"];

      state.productsBySubCategory = state.products.filter((product) =>
        keys.every((key) =>
          product[key].toLowerCase().includes(action.payload[key].toLowerCase())
        )
      );
    },
    getProductsBySubCategoryBrands: (state, action) => {
      const keys = ["category", "subCategory", "brandName"];

      state.productsBySubCategoryBrands = state.products.filter((product) =>
        keys.every((key) =>
          product[key].toLowerCase().includes(action.payload[key].toLowerCase())
        )
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
        state.error = null;
      })
      .addCase(fetchProducts, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleProduct.pending, (state) => {
        state.loadingSingleProduct = true;
        state.error = null;
      })
      .addCase(fetchSingleProduct.fulfilled, (state, action) => {
        state.loadingSingleProduct = false;
        state.singleProduct = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleProduct.rejected, (state, action) => {
        state.loadingSingleProduct = false;
        state.error = action.payload;
      });
  },
});

export const productsSelector = (state) => state.products;

export const {
  setFilteredProducts,
  handleFilteredProducts,
  setCurrentProduct,
  getProductsByCategory,
  getProductsBySubCategory,
  getProductsBySubCategoryBrands,
  setCurrentProductsList,
} = productsSlice.actions;
export default productsSlice.reducer;

// export const { useGetProductsQuery, useGetSingleProductQuery } = productsSlice;

// export default productSlice.reducer;
