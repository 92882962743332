import React from "react";
import AsideMenus from "./AsideMenus";
import { useSelector } from "react-redux";
import { categoriesSelector } from "services/common";
import SubCategoryItem from "./SubCategoryItem";
import { useActions } from "hooks/useActions";
import { useParams } from "react-router-dom";

const Navigation = ({ openNavigation, setOpenNavigation }) => {
  const { navSubCategories } = useSelector(categoriesSelector);
  const { setCurrentCategory } = useActions();
  const { categoryName } = useParams();

  const handleMouseLeave = () => {
    setOpenNavigation(false);

    if (categoryName) {
      setCurrentCategory(decodeURIComponent(categoryName));
    }
  };

  return (
    <div
      className={`navigation bg-white shadow-sm absolute h-[85vh] left-1/2 right-1/2 top-16 overflow-y-auto ${
        openNavigation ? "flex" : "hidden"
      } lg:w-[960px] xl:w-[1200px]`}
      onMouseLeave={handleMouseLeave}
    >
      {/* LEFT MENU */}
      <div className="w-[250px] fixed h-[85vh]">
        <AsideMenus />
      </div>

      {/* RIGHT CONTENT */}
      <div className="text-gray-800 pt-4 pb-96 pl-[260px] flex-1">
        <div className="grid lg:grid-cols-3 xl:grid-cols-4">
          {navSubCategories.map((subCategory) => (
            <SubCategoryItem key={subCategory._id} subCategory={subCategory} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
