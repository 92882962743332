import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useActions } from "hooks/useActions";
import ProductsList from "components/product/ProductsList";
import { useSelector } from "react-redux";
import { productsSelector } from "services/products";
import { IoChevronForwardOutline } from "react-icons/io5";
import { capitalizeFirstLetters } from "libs/helpers";
import useTitle from "hooks/useTitle";

const SubCategoryBrands = ({ search, isOnline, setOpenMobileNavigation }) => {
  const { category, subCategory, brand } = useParams();
  useTitle(
    `Products : ${capitalizeFirstLetters(category)} -> ${capitalizeFirstLetters(
      subCategory
    )} -> ${capitalizeFirstLetters(brand)}`
  );
  const { products, productsBySubCategoryBrands } =
    useSelector(productsSelector);

  const { getProductsBySubCategoryBrands, setCurrentProductsList } =
    useActions();

  useEffect(() => {
    if (products?.length) {
      getProductsBySubCategoryBrands({
        category: decodeURIComponent(category),
        subCategory: decodeURIComponent(subCategory),
        brandName: decodeURIComponent(brand),
      });
    }

    setOpenMobileNavigation(false);

    // eslint-disable-next-line
  }, [products, category, subCategory, brand]);

  useEffect(() => {
    if (productsBySubCategoryBrands?.length) {
      setCurrentProductsList(productsBySubCategoryBrands);
    }

    // eslint-disable-next-line
  }, [productsBySubCategoryBrands]);

  return (
    <div>
      <ul className="py-4 px-2 mb-4 flex sm:items-center">
        <li className="flex items-center text-sm">
          <Link to={`/products/categories/${encodeURIComponent(category)}`}>
            {capitalizeFirstLetters(category)}
          </Link>
          <span className="mt-0.5">
            <IoChevronForwardOutline
              size={15}
              color="rgba(107, 114, 128, 0.9)"
            />
          </span>
        </li>
        <li className="flex items-center text-sm">
          <Link
            to={`/products/categories/${encodeURIComponent(
              category
            )}/${encodeURIComponent(subCategory)}`}
          >
            {capitalizeFirstLetters(subCategory)}
          </Link>
          <span className="mt-0.5">
            <IoChevronForwardOutline
              size={15}
              color="rgba(107, 114, 128, 0.9)"
            />
          </span>
        </li>
        <li className="flex items-center text-sm text-gray-600">
          {capitalizeFirstLetters(brand)}
        </li>
      </ul>
      <section>
        <ProductsList
          isOnline={isOnline}
          search={search}
          productData={productsBySubCategoryBrands}
        />
      </section>
    </div>
  );
};

SubCategoryBrands.propTypes = {
  search: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
  setOpenMobileNavigation: PropTypes.func.isRequired,
};

export default SubCategoryBrands;
