import { Link } from "react-router-dom";
import { getProductSlug, capitalizeFirstLetters } from "libs/helpers";
import { useActions } from "hooks/useActions";

const ProductItem = ({ product, isOnline }) => {
  const { featuredImage, productName, brandName, units, priceCode3, hasImage } =
    product;

  const { setCurrentProduct } = useActions();

  const handleClick = (product) => {
    if (isOnline) return;

    setCurrentProduct(product);
  };

  return (
    <Link
      to={`/products/${encodeURIComponent(getProductSlug(product))}`}
      onClick={() => handleClick(product)}
    >
      <div className="flex gap-4 p-4 radius-sm bg-white min-h-[150px] shadow cursor-pointer hover:shadow-md transition-shadow duration-500 ease-in-out xs:min-h-0">
        <img
          src={!hasImage || !isOnline ? "/not-found-img.png" : featuredImage}
          alt={productName}
          className="w-1/5"
          loading="lazy"
        />
        <div className="flex flex-col w-4/5">
          <h2 className="font-bold text-md mb-2 text-truncate text-gray-600">
            {capitalizeFirstLetters(productName)}
          </h2>
          <p className="text-sm">
            <span className="text-xs italic">Brand:</span> {brandName}
          </p>
          <p className="text-sm">
            <span className="text-xs italic">Unit of Measurement:</span> {units}
          </p>
          <h3 className="text-lg font-bold">${priceCode3}</h3>
        </div>
      </div>
    </Link>
  );
};

export default ProductItem;
