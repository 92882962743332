import React from "react";
import { Link } from "react-router-dom";

const SubCategoryItem = ({ subCategory }) => {
  return (
    <div className="mb-4 overflow-y-auto">
      <h2 className="text-md font-semibold text-black tracking-wide mb-2">
        <Link
          to={`/products/categories/${encodeURIComponent(
            subCategory?.category?.toLowerCase()
          )}/${encodeURIComponent(subCategory?.name?.toLowerCase())}`}
        >
          {subCategory?.name}
        </Link>
      </h2>
      {subCategory?.brands.map((brand) => (
        <p key={brand} className="text-sm font-normal text-black leading-7">
          <Link
            to={`/products/categories/${encodeURIComponent(
              subCategory?.category?.toLowerCase()
            )}/${encodeURIComponent(
              subCategory?.name?.toLowerCase()
            )}/${encodeURIComponent(brand?.toLowerCase())}`}
          >
            {brand}
          </Link>
        </p>
      ))}
    </div>
  );
};

export default SubCategoryItem;
