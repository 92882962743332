import { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { categoriesSelector } from "services/common";
import { utilsSelector } from "services/utils";
import { useActions } from "hooks/useActions";
import { capitalizeFirstLetters } from "libs/helpers";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&.Mui-expanded": {
    margin: 0,
  },
  "&:last-of-type": {
    borderRadius: 5,
  },

  "&.MuiPaper-root": {
    background: "transparent",
    marginBottom: "1rem",
    borderRadius: 5,

    "&::before": {
      background: "transparent",
    },
  },

  //   [theme.breakpoints.only("xs")]: {
  //     display: (props) => (props.menuSlideIn ? "none" : "block"),
  //   },
}));

const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => !["selected"].includes(prop),
})(({ theme, selected }) => ({
  padding: 0,
  paddingRight: 5,
  background: selected ? "#22358e" : "inherit",
  borderRadius: 5,

  "&:hover": {
    background: "#3f51b5",
    "& .MuiTypography-root": {
      color: "#fff !important",
      borderRadius: 5,
    },

    "& .MuiSvgIcon-root": {
      fill: "#fff",
    },
  },

  "& .MuiTypography-root": {
    color: selected ? "#fff" : "#22358e",
  },

  "& .MuiSvgIcon-root": {
    fontSize: "2.5rem",
    fill: selected ? "#fff" : "#22358e",
  },

  "&.Mui-expanded": {
    minHeight: "auto",
  },

  "& .MuiAccordionSummary-content": {
    margin: 0,

    "&.Mui-expanded": {
      margin: 0,
    },
  },

  "& .MuiListItemButton-root": {
    "&:hover": {
      background: "#3f51b5",
      "& .MuiTypography-root": {
        color: "#fff",
        borderRadius: 5,
      },

      "& .MuiSvgIcon-root": {
        fill: "#fff",
      },
    },
  },
}));

const SubCategoryTitle = styled(ListItemButton)(({ theme }) => ({
  "& .MuiTypography-root": {
    color: "#22358e",
    fontSize: ".9rem",
  },
}));

// const StyledListItemButton = styled(ListItemButton, {
//   shouldForwardProps: (prop) => !["menu", "selectedMenu"].includes(prop),
// })(({ theme, menu, selectedMenu }) => ({
//   marginBottom: !menu.hasOwnProperty("subMenus") ? "1rem" : 0,

//   "&.Mui-selected": {
//     background: menu.id === selectedMenu ? "red" : "",

//     borderRadius: ".5rem",

//     "& .MuiTypography-root": {
//       color: "#fff",
//     },
//   },

//   "&:hover": {
//     borderRadius: 5,
//     background: !menu.hasOwnProperty("subMenus") ? "#ccc" : "transparent",
//   },
// }));

const DropDownMenu = styled(ListItemButton)({
  "&:hover": {
    background: "transparent",
  },
});

const SubCategoryAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => !["selected"].includes(prop),
})(({ theme, selected }) => ({
  padding: 0,
  paddingRight: 5,
  background: selected ? "#3f50b5" : "inherit",
  borderRadius: 5,

  // Removes the hovered state background from the button
  "& .MuiListItemButton-root": {
    "&:hover": {
      background: "transparent",
    },

    "&.MuiTypography-root": {
      "&.MuiListItemButton-root": {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
  },

  "& .MuiTypography-root": {
    color: selected ? "#fff !important" : "#22358e",
  },

  "& .MuiSvgIcon-root": {
    fontSize: "1.25rem",
    fill: selected ? "#fff" : "#22358e",
  },

  "&:hover": {
    background: "#3f51b5",
    "& .MuiTypography-root": {
      color: "#fff",
      borderRadius: 5,
    },

    "& .MuiSvgIcon-root": {
      fill: "#fff",
    },
  },

  "&.Mui-expanded": {
    minHeight: "auto",
  },

  "& .MuiAccordionSummary-content": {
    margin: 0,

    "&.Mui-expanded": {
      margin: 0,
    },
  },
}));

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => !["categorySelected"].includes(prop),
})(({ categorySelected }) => ({
  "& > .MuiTypography-root": {
    fontSize: ".9rem",
    color: categorySelected ? "#fff" : "#22358e",
  },
}));

const SelectedBrandButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => !["brandSelected"].includes(prop),
})(({ brandSelected }) => ({
  background: brandSelected ? "rgba(0, 0, 0, 0.04)" : "transparent",
}));

const MobileAsideListItem = () => {
  const {
    category: categoryName,
    subCategory: subCategoryName,
    brand: brandName,
  } = useParams();

  // const [selectedMenu, setSelectedMenu] = useState(0);
  // const [selectedSubMenu, setSelectedSubMenu] = useState(0);

  const { categories, filteredSubCategories, filteredBrandCategories } =
    useSelector(categoriesSelector);
  const { expandedPanel, expandedSubPanel } = useSelector(utilsSelector);

  // Number of brands that will be displayed at a time
  const [numberOfBrands, setNumberOfBrands] = useState(5);

  const {
    handleFilterSubCategoriesData,
    handleFilterBrandCategories,
    setExpandedPanel,
    setExpandedSubPanel,
  } = useActions();

  const handleChange = (panelName) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panelName.toLowerCase() : false);
    handleFilterSubCategoriesData(panelName);
    setExpandedSubPanel(false);
    setNumberOfBrands(5);
  };

  const handleSubcategoryChange =
    (panelName, category) => (event, isExpanded) => {
      setExpandedSubPanel(isExpanded ? panelName : false);
      handleFilterBrandCategories({
        category,
        subCategory: panelName,
      });

      setNumberOfBrands(5);
    };

  const handleShowMore = () => {
    setNumberOfBrands(numberOfBrands + 30);
  };

  return (
    <List sx={{ width: "100%" }} component="nav">
      {categories.map((category) => (
        <StyledAccordion
          expanded={expandedPanel === category?.categoryName?.toLowerCase()}
          onChange={handleChange(category.categoryName)}
          TransitionProps={{ unmountOnExit: true }}
          elevation={0}
          key={category._id}
        >
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: "1.5rem" }} />}
            aria-controls={`${category?.categoryName}-content`}
            id="categories"
            selected={
              decodeURIComponent(category?.categoryName.toLowerCase()) ===
              decodeURIComponent(categoryName?.toLowerCase())
            }
          >
            <DropDownMenu disableRipple>
              <ListItemText>{category.categoryName}</ListItemText>
            </DropDownMenu>
          </StyledAccordionSummary>
          <AccordionDetails>
            <ListItemButton
              disableRipple
              key={category._id}
              style={{
                justifyContent: "center",
              }}
              component={Link}
              to={`/products/categories/${encodeURIComponent(
                category.categoryName?.toLowerCase()
              )}`}
            >
              <StyledListItemText
                categorySelected={
                  decodeURIComponent(category.categoryName) ===
                  decodeURIComponent(categoryName?.toLowerCase())
                }
              >
                All
              </StyledListItemText>
            </ListItemButton>
            {filteredSubCategories?.length > 0 ? (
              filteredSubCategories.map((subCategory) => (
                <StyledAccordion
                  expanded={
                    expandedSubPanel === decodeURIComponent(subCategory.name)
                  }
                  onChange={handleSubcategoryChange(
                    decodeURIComponent(subCategory.name),
                    decodeURIComponent(category.categoryName)
                  )}
                  TransitionProps={{ unmountOnExit: true }}
                  elevation={0}
                  key={subCategory._id}
                >
                  <SubCategoryAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${subCategory.name}-content`}
                    id={subCategory.name}
                    selected={
                      decodeURIComponent(categoryName?.toLowerCase()) ===
                        category.categoryName.toLowerCase() &&
                      decodeURIComponent(subCategoryName?.toLowerCase()) ===
                        subCategory.name.toLowerCase()
                    }
                  >
                    <SubCategoryTitle
                      disableRipple
                      component={Link}
                      to={`/products/categories/${encodeURIComponent(
                        category.categoryName?.toLowerCase()
                      )}/${encodeURIComponent(
                        subCategory.name?.toLowerCase()
                      )}`}
                    >
                      <ListItemText>{subCategory.name}</ListItemText>
                    </SubCategoryTitle>
                  </SubCategoryAccordionSummary>
                  <AccordionDetails>
                    <ListItemButton
                      disableRipple
                      component={Link}
                      to={`/products/categories/${encodeURIComponent(
                        category.categoryName?.toLowerCase()
                      )}/${encodeURIComponent(
                        subCategory.name?.toLowerCase()
                      )}`}
                    >
                      <StyledListItemText>All</StyledListItemText>
                    </ListItemButton>
                    {expandedPanel &&
                      expandedSubPanel &&
                      filteredBrandCategories
                        .slice(0, numberOfBrands)
                        .map((brand, index) => (
                          <SelectedBrandButton
                            disableRipple
                            key={`${index}`}
                            component={Link}
                            to={`/products/categories/${encodeURIComponent(
                              category.categoryName?.toLowerCase()
                            )}/${encodeURIComponent(
                              subCategory.name?.toLowerCase()
                            )}/${encodeURIComponent(brand?.toLowerCase())}`}
                            brandSelected={
                              decodeURIComponent(
                                categoryName?.toLowerCase()
                              ) === category.categoryName.toLowerCase() &&
                              decodeURIComponent(
                                subCategoryName?.toLowerCase()
                              ) === subCategory.name.toLowerCase() &&
                              decodeURIComponent(brandName?.toLowerCase()) ===
                                brand?.toLowerCase()
                            }
                          >
                            <StyledListItemText>
                              {capitalizeFirstLetters(brand)}
                            </StyledListItemText>
                          </SelectedBrandButton>
                        ))}
                    {expandedPanel &&
                      expandedSubPanel &&
                      numberOfBrands < filteredBrandCategories.length && (
                        <p
                          className="pl-4 pt-2 cursor-pointer font-semibold underline"
                          onClick={() => handleShowMore()}
                        >
                          See More!
                        </p>
                      )}
                  </AccordionDetails>
                </StyledAccordion>
              ))
            ) : (
              <ListItemButton
                disableRipple
                key={category._id}
                style={{ justifyContent: "center" }}
                component={Link}
                to={`/products/products/categories/${encodeURIComponent(
                  category.categoryName?.toLowerCase()
                )}`}
              >
                <ListItemText>{category.categoryName}</ListItemText>
              </ListItemButton>
            )}
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </List>
  );
};

export default MobileAsideListItem;
