import { useEffect } from "react";
import ProductsList from "components/product/ProductsList";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { productsSelector } from "services/products";
import { useActions } from "hooks/useActions";

const Products = ({ isOnline, search }) => {
  const { products } = useSelector(productsSelector);
  const { setCurrentProductsList } = useActions();

  useEffect(() => {
    if (products?.length) {
      setCurrentProductsList(products);
    }

    // eslint-disable-next-line
  }, [products]);

  return (
    <div>
      <p>Products</p>
      <section>
        <ProductsList
          isOnline={isOnline}
          search={search}
          productData={products}
        />
      </section>
    </div>
  );
};

Products.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
};

export default Products;
