import React from "react";
import AsideMenuItem from "./AsideMenuItem";
import { useSelector } from "react-redux";
import { categoriesSelector } from "services/common";

const AsideMenus = () => {
  const { categories } = useSelector(categoriesSelector);

  return (
    <ul className="py-2 text-gray-800 bg-gray-100 h-[85vh] hidden lg:block">
      {categories?.map((category) => (
        <AsideMenuItem key={category._id} category={category} />
      ))}
    </ul>
  );
};

export default AsideMenus;
