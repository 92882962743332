import MobileAsideListItem from "./MobileAsideListItem";
import PropTypes from "prop-types";

const MobileAside = ({ openNavigation }) => {
  return (
    <aside
      className={`navigation h-screen pt-24 pb-20 bg-gray-100 fixed top-12  px-4 overflow-y-auto transition-all ease-in-out duration-200 ${
        openNavigation ? "left-0" : "-left-72"
      } sm:pt-8 lg:hidden`}
    >
      <MobileAsideListItem />
    </aside>
  );
};

MobileAside.propTypes = {
  openNavigation: PropTypes.bool.isRequired,
};

export default MobileAside;
