import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "services/axios";

const initialState = {
  loading: false,
  categories: [],
  currentCategory: null,
  subCategories: [],
  brandsCategories: [],
  navSubCategories: [],
  filteredSubCategories: [],
  filteredBrandCategories: [],
  error: null,
};

export const getCategories = createAsyncThunk(
  "getCategories",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/api/categories");
      return data.data.reverse();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllSubcategories = createAsyncThunk(
  "subCategories",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get(`/api/sub-categories`);

      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Something went wrong");
    }
  }
);

export const getBrandsCategories = createAsyncThunk(
  "get-brands-categories",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get(`/api/brand-name`);

      return data.data.filter((brand) => brand.isActivate);
    } catch (error) {
      return (
        thunkAPI.rejectWithValue(error.response.data.message) ||
        thunkAPI.rejectWithValue("Something went wrong")
      );
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    handleFilterNavSubCategories: (state, action) => {
      const keys = ["category"];
      const newSubCategories = [...state.subCategories]
        .filter((subCategory) =>
          subCategory.category
            .toLowerCase()
            .includes(action.payload.toLowerCase())
        )
        .map((sub) => {
          const result = [...state.brandsCategories]
            .filter((brand) =>
              keys.every(
                (key) =>
                  brand[key].toLowerCase().includes(sub[key].toLowerCase()) &&
                  sub.name.toLowerCase() === brand.subCategory.toLowerCase()
              )
            )
            .map((brandName) => brandName.name)
            .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
          return {
            ...sub,
            brands: [...new Set(result)],
          };
        });
      state.navSubCategories = newSubCategories;
    },
    handleFilterSubCategoriesData: (state, action) => {
      const newSubCategories = [...state.subCategories].filter((subCategory) =>
        subCategory.category
          .toLowerCase()
          .includes(action.payload.toLowerCase())
      );
      state.filteredSubCategories = newSubCategories;
    },
    handleFilterBrandCategories: (state, action) => {
      const keys = ["category", "subCategory"];

      const newBrandCategories = [...state.brandsCategories]
        .filter((brand) =>
          keys.every((key) =>
            brand[key].toLowerCase().includes(action.payload[key].toLowerCase())
          )
        )
        .map((brandName) => brandName.name)
        .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

      state.filteredBrandCategories = newBrandCategories;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
        state.error = null;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllSubcategories.fulfilled, (state, action) => {
        state.subCategories = action.payload;
      })
      .addCase(getBrandsCategories.fulfilled, (state, action) => {
        state.brandsCategories = action.payload;
      });
  },
});

export const {
  setCurrentCategory,
  handleFilterNavSubCategories,
  handleFilterSubCategoriesData,
  handleFilterBrandCategories,
} = commonSlice.actions;
export const categoriesSelector = (state) => state.common;

export default commonSlice.reducer;
