import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useActions } from "hooks/useActions";
import ProductsList from "components/product/ProductsList";
import { useSelector } from "react-redux";
import { productsSelector } from "services/products";
import { IoChevronForwardOutline } from "react-icons/io5";
import { capitalizeFirstLetters } from "libs/helpers";
import useTitle from "hooks/useTitle";

const SubCategory = ({ search, isOnline, setOpenMobileNavigation }) => {
  const { category, subCategory } = useParams();
  useTitle(
    `Products : ${capitalizeFirstLetters(category)} -> ${capitalizeFirstLetters(
      subCategory
    )} `
  );
  const { products, productsBySubCategory } = useSelector(productsSelector);

  const { getProductsBySubCategory, setCurrentProductsList } = useActions();

  useEffect(() => {
    if (products?.length) {
      getProductsBySubCategory({
        category: decodeURIComponent(category),
        subCategory: decodeURIComponent(subCategory),
      });
    }

    setOpenMobileNavigation(false);

    // eslint-disable-next-line
  }, [products, category, subCategory]);

  useEffect(() => {
    if (productsBySubCategory?.length) {
      setCurrentProductsList(productsBySubCategory);
    }

    // eslint-disable-next-line
  }, [productsBySubCategory]);

  return (
    <div>
      <ul className="py-4 px-2 mb-4 flex sm:items-center">
        <li className="flex items-center text-sm">
          <Link to={`/products/categories/${encodeURIComponent(category)}`}>
            {capitalizeFirstLetters(category)}
          </Link>
          <span className="mt-0.5">
            <IoChevronForwardOutline
              size={15}
              color="rgba(107, 114, 128, 0.9)"
            />
          </span>
        </li>
        <li className="flex items-center text-sm text-gray-600">
          {capitalizeFirstLetters(subCategory)}
        </li>
      </ul>
      <section>
        <ProductsList
          isOnline={isOnline}
          search={search}
          productData={productsBySubCategory}
        />
      </section>
    </div>
  );
};

SubCategory.propTypes = {
  search: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
  setOpenMobileNavigation: PropTypes.func.isRequired,
};

export default SubCategory;
