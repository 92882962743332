import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import PropTypes from "prop-types";
import Navigation from "./DeskTopNavigation";
import MobileAside from "./MobileAside";

const Layout = ({
  search,
  setSearch,
  setOpenMobileNavigation,
  openMobileNavigation,
}) => {
  const [openNavigation, setOpenNavigation] = useState(false);

  return (
    <div className="bg-gray-50 min-h-screen">
      <Header
        search={search}
        setSearch={setSearch}
        setOpenNavigation={setOpenNavigation}
        setOpenMobileNavigation={setOpenMobileNavigation}
      >
        <Navigation
          openNavigation={openNavigation}
          setOpenNavigation={setOpenNavigation}
        />
      </Header>

      <div className="py-4">
        <main className="pt-32 px-2 sm:px-6 sm:pt-20 overflow-x-hidden">
          <MobileAside openNavigation={openMobileNavigation} />
          <Outlet />
        </main>
      </div>
    </div>
  );
};

Layout.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default Layout;
