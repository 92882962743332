import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expandedPanel: false,
  expandedSubPanel: false,
};

const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setExpandedPanel: (state, action) => {
      state.expandedPanel = action.payload;
    },
    setExpandedSubPanel: (state, action) => {
      state.expandedSubPanel = action.payload;
    },
  },
});

export const utilsSelector = (state) => state.utils;

export const { setExpandedPanel, setExpandedSubPanel } = utilsSlice.actions;
export default utilsSlice.reducer;
