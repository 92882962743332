import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router";
import ProductDetailsHeading from "components/product/ProductDetailsHeading";
import ProductInformation from "components/product/ProductInformation";
import { useActions } from "hooks/useActions";
import { useSelector } from "react-redux";
import { productsSelector } from "services/products";
import Spinner from "utils/Spinner";
import PropTypes from "prop-types";
import GoBack from "utils/GoBack";
import useTitle from "hooks/useTitle";
import { capitalizeFirstLetters } from "libs/helpers";

const SingleProduct = ({ isOnline }) => {
  const productURL = useParams();
  const transformURL = productURL?.itemCode?.split("-");

  const itemCode = transformURL[transformURL.length - 1];

  const { loadingSingleProduct, singleProduct } = useSelector(productsSelector);
  const { fetchSingleProduct } = useActions();

  useTitle(`Products : ${capitalizeFirstLetters(singleProduct?.productName)}`);

  useEffect(() => {
    if (isOnline) {
      fetchSingleProduct(itemCode);
    }

    // eslint-disable-next-line
  }, [itemCode, isOnline]);

  return (
    <div className="min-h-screen bg-white -mt-12 sm:mt-0">
      {loadingSingleProduct ? (
        <Spinner loaderHeight="80vh" />
      ) : (
        <Fragment>
          <ProductDetailsHeading product={singleProduct} />
          <GoBack />
          <ProductInformation product={singleProduct} isOnline={isOnline} />
        </Fragment>
      )}
    </div>
  );
};

SingleProduct.propTyoes = {
  isOnline: PropTypes.bool.isRequired,
};

export default SingleProduct;
