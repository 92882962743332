import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "layouts/Layout";
import Products from "pages/Products";
import SingleProduct from "pages/SingleProduct";
import { useActions } from "hooks/useActions";
import { useConnectivity } from "hooks/useConnectivity";
import CategoryName from "pages/CategoryName";
import SubCategory from "pages/SubCategory";
import SubCategoryBrands from "pages/SubCategoryBrands";
import useTitle from "hooks/useTitle";

function App() {
  useTitle("Products : Brand Central Wholesale");
  const [search, setSearch] = useState("");
  const [openMobileNavigation, setOpenMobileNavigation] = useState(false);

  const {
    fetchProducts,
    setFilteredProducts,
    getCategories,
    getAllSubcategories,
    getBrandsCategories,
    setCurrentCategory,
  } = useActions();

  const { isOnline } = useConnectivity();

  useEffect(() => {
    setFilteredProducts([]);
    setCurrentCategory(null);

    if (isOnline) {
      fetchProducts();
      getCategories();
      getAllSubcategories();
      getBrandsCategories();
    }

    // eslint-disable-next-line
  }, [isOnline]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            search={search}
            setSearch={setSearch}
            openMobileNavigation={openMobileNavigation}
            setOpenMobileNavigation={setOpenMobileNavigation}
          />
        }
      >
        <Route
          index
          element={<Products search={search} isOnline={isOnline} />}
        />
        <Route path="products">
          <Route
            index
            element={<Products search={search} isOnline={isOnline} />}
          />
          <Route
            path=":itemCode"
            element={<SingleProduct isOnline={isOnline} />}
          />
          <Route path="categories">
            <Route
              index
              element={<Products search={search} isOnline={isOnline} />}
            />
            <Route path=":category">
              <Route
                index
                element={
                  <CategoryName
                    isOnline={isOnline}
                    search={search}
                    setOpenMobileNavigation={setOpenMobileNavigation}
                  />
                }
              />
              <Route path=":subCategory">
                <Route
                  index
                  element={
                    <SubCategory
                      isOnline={isOnline}
                      search={search}
                      setOpenMobileNavigation={setOpenMobileNavigation}
                    />
                  }
                />
                <Route
                  path=":brand"
                  element={
                    <SubCategoryBrands
                      isOnline={isOnline}
                      search={search}
                      setOpenMobileNavigation={setOpenMobileNavigation}
                    />
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <div className="h-[80vh] flex items-center justify-center">
              <h1 className="text-[30px] font-semibold sm:text-[40px]">
                Page not found
              </h1>
            </div>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
